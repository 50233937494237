import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The upload of a file.
   *
   * Variables of this type must be set to null in mutations. They will be
   * replaced with a filename from a following multipart part containing a
   * binary file.
   *
   * See: https://github.com/jaydenseric/graphql-multipart-request-spec
   */
  UploadType: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
};

export type Query = {
  __typename?: 'Query';
  /** The ID of the object */
  connection?: Maybe<ConnectionType>;
  /** All sheet in the database. */
  connections?: Maybe<ConnectionTypeConnection>;
  /** All sheet hits in the database. */
  connectionHits?: Maybe<ConnectionHitTypeConnection>;
  /** Hits in the sheet by a given period. */
  connectionHitsByPeriod?: Maybe<Array<ConnectionHitsByPeriodType>>;
  /** A public sheet in the database. */
  sheetPublic?: Maybe<SheetPublicType>;
  /** The ID of the object */
  sheet?: Maybe<SheetType>;
  /** All sheet in the database. */
  sheets?: Maybe<SheetTypeConnection>;
  /** All sheet hits in the database. */
  sheetHits?: Maybe<SheetHitTypeConnection>;
  /** Hits in the sheet by a given period. */
  sheetHitsByPeriod?: Maybe<Array<ConnectionHitsByPeriodType>>;
  /** The currently logged user. */
  me?: Maybe<UserType>;
  /** The ID of the object */
  subscriptionPlan?: Maybe<SubscriptionPlanType>;
  /** All public subscription plans. */
  subscriptionPlans?: Maybe<Array<SubscriptionPlanType>>;
  _debug?: Maybe<DjangoDebug>;
  /** Deactivate a user */
  userDeactivate?: Maybe<UserDeactivateMutationPayload>;
};

export type QueryConnectionArgs = {
  id: Scalars['ID'];
};

export type QueryConnectionsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  starred?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryConnectionHitsArgs = {
  connection?: Maybe<Scalars['ID']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Range?: Maybe<Scalars['DateTime']>;
  method_Iexact?: Maybe<Scalars['String']>;
  path_Iexact?: Maybe<Scalars['String']>;
  path_Icontains?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type QueryConnectionHitsByPeriodArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
  periodType: PeriodType;
  connection?: Maybe<Scalars['ID']>;
  groupByMethod?: Maybe<Scalars['Boolean']>;
  tz?: Maybe<Scalars['String']>;
};

export type QuerySheetPublicArgs = {
  id: Scalars['UUID'];
};

export type QuerySheetArgs = {
  id: Scalars['ID'];
};

export type QuerySheetsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  starred?: Maybe<Scalars['Boolean']>;
};

export type QuerySheetHitsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  connection?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Range?: Maybe<Scalars['DateTime']>;
  method_Iexact?: Maybe<Scalars['String']>;
  path_Iexact?: Maybe<Scalars['String']>;
  path_Istartswith?: Maybe<Scalars['String']>;
};

export type QuerySheetHitsByPeriodArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
  periodType: PeriodType;
  sheet?: Maybe<Scalars['ID']>;
  groupByMethod?: Maybe<Scalars['Boolean']>;
  tz?: Maybe<Scalars['String']>;
};

export type QuerySubscriptionPlanArgs = {
  id: Scalars['ID'];
};

/** Connection type. */
export type ConnectionType = Node &
  ConnectionInterface & {
    __typename?: 'ConnectionType';
    /** The ID of the object. */
    id: Scalars['ID'];
    /** The connection's original url. */
    url?: Maybe<Scalars['String']>;
    /** Original connection's id as UUID */
    pk: Scalars['UUID'];
    /** The kind of the result. */
    kind: ConnectionKind;
    /** The connection's name. */
    name?: Maybe<Scalars['String']>;
    /** The connection's api url. */
    apiUrl: Scalars['String'];
    /** When this connection was created. */
    createdAt: Scalars['DateTime'];
    /** When this connection was updated. */
    updatedAt: Scalars['DateTime'];
    /** Total hits this month in the connection's api. */
    hitsCached: Scalars['Int'];
    /** Total lifetime hits in the connection's api. */
    hitsCachedTotal: Scalars['Int'];
    /** TWhen this connection was last hitted. */
    lastHittedAt?: Maybe<Scalars['DateTime']>;
    /** Total rows in this connection. */
    totalRows?: Maybe<Scalars['Int']>;
    /** For how longs the response in this connection is cached. */
    ttl: Scalars['Int'];
    /** This connection's hits limit. */
    limit: Scalars['Int'];
    /** This connection's remaining hits. */
    remaining: Scalars['Int'];
    /** This connection's remaining hits. */
    starred?: Maybe<Scalars['Boolean']>;
    /** If the api key is enabled. */
    apiKeyEnabled?: Maybe<Scalars['Boolean']>;
    /** The api key. */
    apiKey?: Maybe<Scalars['String']>;
    /** List of allowed methods for this connection. */
    allowedMethods?: Maybe<Array<Scalars['String']>>;
    /** If the allowed origins list is enabled. */
    allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
    /** List of allowed origins for this conenction. */
    allowedOrigins?: Maybe<Array<Scalars['String']>>;
    /** If the allowed ips list is enabled. */
    allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
    /** List of allowed ips for this connection. */
    allowedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** If the allowed ips list is enabled. */
    source: ConnectionUnion;
  };

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type ConnectionInterface = {
  /** Original connection's id as UUID */
  pk: Scalars['UUID'];
  /** The kind of the result. */
  kind: ConnectionKind;
  /** The connection's name. */
  name?: Maybe<Scalars['String']>;
  /** The connection's api url. */
  apiUrl: Scalars['String'];
  /** When this connection was created. */
  createdAt: Scalars['DateTime'];
  /** When this connection was updated. */
  updatedAt: Scalars['DateTime'];
  /** Total hits this month in the connection's api. */
  hitsCached: Scalars['Int'];
  /** Total lifetime hits in the connection's api. */
  hitsCachedTotal: Scalars['Int'];
  /** TWhen this connection was last hitted. */
  lastHittedAt?: Maybe<Scalars['DateTime']>;
  /** Total rows in this connection. */
  totalRows?: Maybe<Scalars['Int']>;
  /** For how longs the response in this connection is cached. */
  ttl: Scalars['Int'];
  /** This connection's hits limit. */
  limit: Scalars['Int'];
  /** This connection's remaining hits. */
  remaining: Scalars['Int'];
  /** This connection's remaining hits. */
  starred?: Maybe<Scalars['Boolean']>;
  /** If the api key is enabled. */
  apiKeyEnabled?: Maybe<Scalars['Boolean']>;
  /** The api key. */
  apiKey?: Maybe<Scalars['String']>;
  /** List of allowed methods for this connection. */
  allowedMethods?: Maybe<Array<Scalars['String']>>;
  /** If the allowed origins list is enabled. */
  allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
  /** List of allowed origins for this conenction. */
  allowedOrigins?: Maybe<Array<Scalars['String']>>;
  /** If the allowed ips list is enabled. */
  allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
  /** List of allowed ips for this connection. */
  allowedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Connection kind. */
export enum ConnectionKind {
  Gsheet = 'GSHEET',
  Gdrive = 'GDRIVE',
  Upload = 'UPLOAD',
}

/** Connection union. */
export type ConnectionUnion = SheetType | DriveType | SheetUploadType;

/** Sheet type. */
export type SheetType = Node &
  ConnectionInterface & {
    __typename?: 'SheetType';
    /** The ID of the object. */
    id: Scalars['ID'];
    /** The sheet's url. */
    url: Scalars['String'];
    /** Original connection's id as UUID */
    pk: Scalars['UUID'];
    /** The kind of the result. */
    kind: ConnectionKind;
    /** The connection's name. */
    name?: Maybe<Scalars['String']>;
    /** The connection's api url. */
    apiUrl: Scalars['String'];
    /** When this connection was created. */
    createdAt: Scalars['DateTime'];
    /** When this connection was updated. */
    updatedAt: Scalars['DateTime'];
    /** Total hits this month in the connection's api. */
    hitsCached: Scalars['Int'];
    /** Total lifetime hits in the connection's api. */
    hitsCachedTotal: Scalars['Int'];
    /** TWhen this connection was last hitted. */
    lastHittedAt?: Maybe<Scalars['DateTime']>;
    /** Total rows in this connection. */
    totalRows?: Maybe<Scalars['Int']>;
    /** For how longs the response in this connection is cached. */
    ttl: Scalars['Int'];
    /** This connection's hits limit. */
    limit: Scalars['Int'];
    /** This connection's remaining hits. */
    remaining: Scalars['Int'];
    /** This connection's remaining hits. */
    starred?: Maybe<Scalars['Boolean']>;
    /** If the api key is enabled. */
    apiKeyEnabled?: Maybe<Scalars['Boolean']>;
    /** The api key. */
    apiKey?: Maybe<Scalars['String']>;
    /** List of allowed methods for this connection. */
    allowedMethods?: Maybe<Array<Scalars['String']>>;
    /** If the allowed origins list is enabled. */
    allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
    /** List of allowed origins for this conenction. */
    allowedOrigins?: Maybe<Array<Scalars['String']>>;
    /** If the allowed ips list is enabled. */
    allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
    /** List of allowed ips for this connection. */
    allowedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

/** Drive type. */
export type DriveType = Node &
  ConnectionInterface & {
    __typename?: 'DriveType';
    /** The ID of the object. */
    id: Scalars['ID'];
    /** The drive's url. */
    url: Scalars['String'];
    /** Original connection's id as UUID */
    pk: Scalars['UUID'];
    /** The kind of the result. */
    kind: ConnectionKind;
    /** The connection's name. */
    name?: Maybe<Scalars['String']>;
    /** The connection's api url. */
    apiUrl: Scalars['String'];
    /** When this connection was created. */
    createdAt: Scalars['DateTime'];
    /** When this connection was updated. */
    updatedAt: Scalars['DateTime'];
    /** Total hits this month in the connection's api. */
    hitsCached: Scalars['Int'];
    /** Total lifetime hits in the connection's api. */
    hitsCachedTotal: Scalars['Int'];
    /** TWhen this connection was last hitted. */
    lastHittedAt?: Maybe<Scalars['DateTime']>;
    /** Total rows in this connection. */
    totalRows?: Maybe<Scalars['Int']>;
    /** For how longs the response in this connection is cached. */
    ttl: Scalars['Int'];
    /** This connection's hits limit. */
    limit: Scalars['Int'];
    /** This connection's remaining hits. */
    remaining: Scalars['Int'];
    /** This connection's remaining hits. */
    starred?: Maybe<Scalars['Boolean']>;
    /** If the api key is enabled. */
    apiKeyEnabled?: Maybe<Scalars['Boolean']>;
    /** The api key. */
    apiKey?: Maybe<Scalars['String']>;
    /** List of allowed methods for this connection. */
    allowedMethods?: Maybe<Array<Scalars['String']>>;
    /** If the allowed origins list is enabled. */
    allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
    /** List of allowed origins for this conenction. */
    allowedOrigins?: Maybe<Array<Scalars['String']>>;
    /** If the allowed ips list is enabled. */
    allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
    /** List of allowed ips for this connection. */
    allowedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

/** Upload type. */
export type SheetUploadType = Node &
  ConnectionInterface & {
    __typename?: 'SheetUploadType';
    /** The ID of the object. */
    id: Scalars['ID'];
    /** Original connection's id as UUID */
    pk: Scalars['UUID'];
    /** The kind of the result. */
    kind: ConnectionKind;
    /** The connection's name. */
    name?: Maybe<Scalars['String']>;
    /** The connection's api url. */
    apiUrl: Scalars['String'];
    /** When this connection was created. */
    createdAt: Scalars['DateTime'];
    /** When this connection was updated. */
    updatedAt: Scalars['DateTime'];
    /** Total hits this month in the connection's api. */
    hitsCached: Scalars['Int'];
    /** Total lifetime hits in the connection's api. */
    hitsCachedTotal: Scalars['Int'];
    /** TWhen this connection was last hitted. */
    lastHittedAt?: Maybe<Scalars['DateTime']>;
    /** Total rows in this connection. */
    totalRows?: Maybe<Scalars['Int']>;
    /** For how longs the response in this connection is cached. */
    ttl: Scalars['Int'];
    /** This connection's hits limit. */
    limit: Scalars['Int'];
    /** This connection's remaining hits. */
    remaining: Scalars['Int'];
    /** This connection's remaining hits. */
    starred?: Maybe<Scalars['Boolean']>;
    /** If the api key is enabled. */
    apiKeyEnabled?: Maybe<Scalars['Boolean']>;
    /** The api key. */
    apiKey?: Maybe<Scalars['String']>;
    /** List of allowed methods for this connection. */
    allowedMethods?: Maybe<Array<Scalars['String']>>;
    /** If the allowed origins list is enabled. */
    allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
    /** List of allowed origins for this conenction. */
    allowedOrigins?: Maybe<Array<Scalars['String']>>;
    /** If the allowed ips list is enabled. */
    allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
    /** List of allowed ips for this connection. */
    allowedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

export type ConnectionTypeConnection = {
  __typename?: 'ConnectionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConnectionTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** A Relay edge containing a `ConnectionType` and its cursor. */
export type ConnectionTypeEdge = {
  __typename?: 'ConnectionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ConnectionType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ConnectionHitTypeConnection = {
  __typename?: 'ConnectionHitTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConnectionHitTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ConnectionHitType` and its cursor. */
export type ConnectionHitTypeEdge = {
  __typename?: 'ConnectionHitTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ConnectionHitType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Connection hit type. */
export type ConnectionHitType = Node & {
  __typename?: 'ConnectionHitType';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** When this model was created. */
  createdAt: Scalars['DateTime'];
  /** When this model was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The ip that made this request. */
  ipAddress: Scalars['String'];
  /** The HTTP request method. */
  method: SheetHitMethod;
  /** The HTTP request path. */
  path: Scalars['String'];
  /** The params sent in the request. */
  params: Scalars['JSONString'];
  /** The data sent in the request. */
  data: Scalars['JSONString'];
  /** The kind of the connection. */
  kind?: Maybe<ConnectionKind>;
  user: UserType;
  connection?: Maybe<ConnectionType>;
  sheet?: Maybe<SheetType>;
  drive?: Maybe<DriveType>;
  upload?: Maybe<SheetUploadType>;
  /** If the allowed ips list is enabled. */
  source: ConnectionUnion;
};

/** An enumeration. */
export enum SheetHitMethod {
  /** GET */
  Get = 'GET',
  /** POST */
  Post = 'POST',
  /** PATCH */
  Patch = 'PATCH',
  /** PUT */
  Put = 'PUT',
  /** DELETE */
  Delete = 'DELETE',
}

/** A user in the database. */
export type UserType = Node & {
  __typename?: 'UserType';
  /** The ID of the object. */
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  /** Currently subscribed plan. */
  subscription: SubscriptionPlanType;
  /** How many hits this user sheets had (cached). */
  hitsCached: Scalars['Int'];
  /** How many hits this user sheets had in their lifetime (cached). */
  hitsCachedTotal: Scalars['Int'];
  /*  Subscription ID from plan */
  stripeSubscriptionId: Scalars['String'];
  // Stripe Customer ID
  stripeCustomerId: Scalars['String'];
  /* Subscription Failure Flag*/
  subscriptionFailureFlag: Scalars['Boolean'];
  /** Original user's id as UUID */
  pk?: Maybe<Scalars['UUID']>;
  /** This user's encrypted hash. */
  hash?: Maybe<Scalars['String']>;
  /** This user's hits limit. */
  limit?: Maybe<Scalars['Int']>;
  /** This user's remaining hits. */
  remaining?: Maybe<Scalars['Int']>;
  /**
   * This user's plan (old).
   * @deprecated Use subscription.code instead
   */
  plan?: Maybe<Scalars['String']>;
};

/** Subscription plan type. */
export type SubscriptionPlanType = Node & {
  __typename?: 'SubscriptionPlanType';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The plan's code. */
  code: Scalars['String'];
  /** Is this plan open for anyone to subscribe to. */
  public: Scalars['Boolean'];
  /** This plan's name. */
  name: Scalars['String'];
  /** The plan's price. */
  price: Scalars['Float'];
  /** This plan's paypal id. */
  paypalPlanId?: Maybe<Scalars['String']>;
  /** This product stripe id. */
  stripeProductId?: Maybe<Scalars['String']>;
  /** Is this the most popular plan. */
  mostPopular: Scalars['Boolean'];
  /** Is this plan has a trial period. */
  hasTrial: Scalars['Boolean'];
  /** Maximum number of connections allowed in the plan. */
  maxConnections: Scalars['Int'];
  /** Maximum number of requests allowed in the plan. */
  maxRequests: Scalars['Int'];
  /** Log retention. */
  logRetention?: Maybe<SubscriptionPlanLogRetention>;
  /** Chart tier. */
  chartsTier?: Maybe<SubscriptionPlanChartsTier>;
  /** List of connections allowed for this subscription. */
  connectionsAllowed: Array<Scalars['String']>;
  /** Does this plan have methods change support. */
  methodsSupport: Scalars['Boolean'];
  /** Does this plan have whitelist change support. */
  whitelistSupport: Scalars['Boolean'];
  /** Does this plan have prioritary support. */
  prioritarySupport: Scalars['Boolean'];
  /** Does this plan have advanced queries. */
  advancedQueries: Scalars['Boolean'];
  /** Does this plan have api keys. */
  apiKey: Scalars['Boolean'];
  /** Does this plan have renewable api keys. */
  apiKeyRenewable: Scalars['Boolean'];
  /** Does this plan have custumizable cache. */
  customizableCache: Scalars['Boolean'];
  /** The next tier for this subscription. */
  nextTier?: Maybe<SubscriptionPlanType>;
  /** The description parts. */
  description: Array<Scalars['String']>;
};

/** An enumeration. */
export enum SubscriptionPlanLogRetention {
  /** 1 Month */
  A_1Month = 'A_1MONTH',
  /** 1 Year */
  A_1Year = 'A_1YEAR',
  /** Forever */
  Forever = 'FOREVER',
}

/** An enumeration. */
export enum SubscriptionPlanChartsTier {
  /** Simple */
  Simple = 'SIMPLE',
  /** Advanced */
  Advanced = 'ADVANCED',
}

/** Connection hit by period. */
export type ConnectionHitsByPeriodType = {
  __typename?: 'ConnectionHitsByPeriodType';
  /** Total hits in the period. */
  hits: Scalars['Int'];
  /** The period asked for. */
  period?: Maybe<Scalars['String']>;
  /** Hits in the method (if specified). */
  method?: Maybe<Scalars['String']>;
};

/** Period type for filtering. */
export enum PeriodType {
  Whole = 'WHOLE',
  Hour = 'HOUR',
  Day = 'DAY',
  Weekday = 'WEEKDAY',
}

/** Sheet type for public viewing. */
export type SheetPublicType = ConnectionInterface & {
  __typename?: 'SheetPublicType';
  /** Original connection's id as UUID */
  pk: Scalars['UUID'];
  /** The kind of the result. */
  kind: ConnectionKind;
  /** The connection's name. */
  name?: Maybe<Scalars['String']>;
  /** The connection's api url. */
  apiUrl: Scalars['String'];
  /** When this connection was created. */
  createdAt: Scalars['DateTime'];
  /** When this connection was updated. */
  updatedAt: Scalars['DateTime'];
  /** Total hits this month in the connection's api. */
  hitsCached: Scalars['Int'];
  /** Total lifetime hits in the connection's api. */
  hitsCachedTotal: Scalars['Int'];
  /** TWhen this connection was last hitted. */
  lastHittedAt?: Maybe<Scalars['DateTime']>;
  /** Total rows in this connection. */
  totalRows?: Maybe<Scalars['Int']>;
  /** For how longs the response in this connection is cached. */
  ttl: Scalars['Int'];
  /** This connection's hits limit. */
  limit: Scalars['Int'];
  /** This connection's remaining hits. */
  remaining: Scalars['Int'];
  /** This connection's remaining hits. */
  starred?: Maybe<Scalars['Boolean']>;
  /** If the api key is enabled. */
  apiKeyEnabled?: Maybe<Scalars['Boolean']>;
  /** The api key. */
  apiKey?: Maybe<Scalars['String']>;
  /** List of allowed methods for this connection. */
  allowedMethods?: Maybe<Array<Scalars['String']>>;
  /** If the allowed origins list is enabled. */
  allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
  /** List of allowed origins for this conenction. */
  allowedOrigins?: Maybe<Array<Scalars['String']>>;
  /** If the allowed ips list is enabled. */
  allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
  /** List of allowed ips for this connection. */
  allowedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The sheet's id. */
  id?: Maybe<Scalars['UUID']>;
  /** The sheet's url. */
  url?: Maybe<Scalars['String']>;
};

export type SheetTypeConnection = {
  __typename?: 'SheetTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SheetTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SheetType` and its cursor. */
export type SheetTypeEdge = {
  __typename?: 'SheetTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SheetType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SheetHitTypeConnection = {
  __typename?: 'SheetHitTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SheetHitTypeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SheetHitType` and its cursor. */
export type SheetHitTypeEdge = {
  __typename?: 'SheetHitTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SheetHitType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Sheet hit type. */
export type SheetHitType = Node & {
  __typename?: 'SheetHitType';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** When this model was created. */
  createdAt: Scalars['DateTime'];
  /** When this model was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The ip that made this request. */
  ipAddress: Scalars['String'];
  /** The HTTP request method. */
  method: SheetHitMethod;
  /** The HTTP request path. */
  path: Scalars['String'];
  /** The params sent in the request. */
  params: Scalars['JSONString'];
  /** The data sent in the request. */
  data: Scalars['JSONString'];
  /** The sheet related to this hit. */
  connection: SheetType;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename?: 'DjangoDebug';
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename?: 'DjangoDebugSQL';
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Validate that the connection URL is valid. */
  connectionValidateUrl?: Maybe<ConnectionValidateUrlMutationPayload>;
  /** Create a new connection. */
  connectionCreate?: Maybe<ConnectionCreateMutationPayload>;
  /** Update a connection in the database. */
  connectionUpdate?: Maybe<ConnectionUpdateMutationPayload>;
  /** Delete a connection from the database. */
  connectionDelete?: Maybe<ConnectionDeleteMutationPayload>;
  /** Validate that the sheet URL is valid. */
  sheetValidateUrl?: Maybe<SheetValidateUrlMutationPayload>;
  /** Create a new sheet. */
  sheetCreate?: Maybe<SheetCreateMutationPayload>;
  /** Update a sheet in the database. */
  sheetUpdate?: Maybe<SheetUpdateMutationPayload>;
  /** Delete the sheet from the database. */
  sheetDelete?: Maybe<SheetDeleteMutationPayload>;
  /** Toggle the starred state of the sheety. */
  sheetStarToggle?: Maybe<SheetStarToggleMutationPayload>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebTokenPayload>;
  tokenVerify?: Maybe<VerifyPayload>;
  tokenRefresh?: Maybe<RefreshPayload>;
  /** Create a new user. */
  userCreate?: Maybe<UserCreateMutationPayload>;
  /** Activate a user's registration. */
  userActivate?: Maybe<UserActivateMutationPayload>;
  /** Request to reset a user's password. */
  userResetPassword?: Maybe<UserResetPasswordMutationPayload>;
  /** Confirm the request to reset a user's password. */
  userPasswordConfirm?: Maybe<UserResetPasswordConfirmMutationPayload>;
  /** Mutation that logs the user out. */
  userLogout?: Maybe<UserLogoutMutationPayload>;
  /** Subscribe the user to the given subscription. */
  userSubscriptionCreate?: Maybe<UserSubscriptionCreateMutationPayload>;
  /** Subscribe the user to the given subscription. */
  userSubscriptionChange?: Maybe<UserSubscriptionChangeMutationPayload>;
  /** Confirm the subscription change for the user. */
  userSubscriptionChangeConfirm?: Maybe<UserSubscriptionChangeConfirmMutationPayload>;
  /** The ID of the object */
  node?: Maybe<Node>;
  /** User stripe checkout session create */
  userStripeCheckoutSessionCreate?: Maybe<any>;
};

export type MutationConnectionValidateUrlArgs = {
  input: ConnectionValidateUrlMutationInput;
};

export type MutationConnectionCreateArgs = {
  input: ConnectionCreateMutationInput;
};

export type MutationConnectionUpdateArgs = {
  input: ConnectionUpdateMutationInput;
};

export type MutationConnectionDeleteArgs = {
  input: ConnectionDeleteMutationInput;
};

export type MutationSheetValidateUrlArgs = {
  input: SheetValidateUrlMutationInput;
};

export type MutationSheetCreateArgs = {
  input: SheetCreateMutationInput;
};

export type MutationSheetUpdateArgs = {
  input: SheetUpdateMutationInput;
};

export type MutationSheetDeleteArgs = {
  input: SheetDeleteMutationInput;
};

export type MutationSheetStarToggleArgs = {
  input: SheetStarToggleMutationInput;
};

export type MutationTokenAuthArgs = {
  input: ObtainJsonWebTokenInput;
};

export type MutationTokenVerifyArgs = {
  input: VerifyInput;
};

export type MutationTokenRefreshArgs = {
  input: RefreshInput;
};

export type MutationUserCreateArgs = {
  input: UserCreateMutationInput;
};

export type MutationUserActivateArgs = {
  input: UserActivateMutationInput;
};

export type MutationUserResetPasswordArgs = {
  input: UserResetPasswordMutationInput;
};

export type MutationUserPasswordConfirmArgs = {
  input: UserResetPasswordConfirmMutationInput;
};

export type MutationUserLogoutArgs = {
  input: UserLogoutMutationInput;
};

export type MutationUserSubscriptionCreateArgs = {
  input: UserSubscriptionCreateMutationInput;
};

export type MutationUserSubscriptionChangeArgs = {
  input: UserSubscriptionChangeMutationInput;
};

export type MutationUserSubscriptionChangeConfirmArgs = {
  input: UserSubscriptionChangeConfirmMutationInput;
};

export type MutationNodeArgs = {
  id: Scalars['ID'];
};

/** Validate that the connection URL is valid. */
export type ConnectionValidateUrlMutationPayload = {
  __typename?: 'ConnectionValidateUrlMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the url is valid or not. */
  isValid?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** An error that happened in a mutation. */
export type MutationErrorType = {
  __typename?: 'MutationErrorType';
  /** The field that caused the error, or `null` if it isn't associated with any particular field. */
  field?: Maybe<Scalars['String']>;
  /** The error message. */
  message?: Maybe<Scalars['String']>;
};

export type ConnectionValidateUrlMutationInput = {
  /** The kind of the URL to validate. */
  kind: ConnectionKind;
  /** The connection URL to validate. */
  url: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Create a new connection. */
export type ConnectionCreateMutationPayload = {
  __typename?: 'ConnectionCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  connection?: Maybe<ConnectionType>;
};

export type ConnectionCreateMutationInput = {
  /** The kind of the connection. */
  kind: ConnectionKind;
  /** The upload for upload type. */
  upload?: Maybe<Scalars['UploadType']>;
  /** The sheet's name. */
  name?: Maybe<Scalars['String']>;
  /** The connection's original url. */
  url?: Maybe<Scalars['String']>;
  /** The sheet's owner. */
  user?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Update a connection in the database. */
export type ConnectionUpdateMutationPayload = {
  __typename?: 'ConnectionUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  connection?: Maybe<ConnectionType>;
};

export type ConnectionUpdateMutationInput = {
  /** The sheet's name. */
  name?: Maybe<Scalars['String']>;
  /** A key to protect this sheet's endpoint. */
  apiKey?: Maybe<Scalars['String']>;
  /** List of methods allowed for this sheet. */
  allowedMethods?: Maybe<Array<Scalars['String']>>;
  /** List of origins allowed for this sheet. */
  allowedOrigins?: Maybe<Array<Scalars['String']>>;
  /** List of ips allowed for this sheet. */
  allowedIps?: Maybe<Array<Scalars['String']>>;
  /** For how long should we cache the content (in seconds). */
  ttl?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** If the api is starred. */
  starred?: Maybe<Scalars['Boolean']>;
  /** If the api key is enabled. */
  apiKeyEnabled?: Maybe<Scalars['Boolean']>;
  /** If the allowed origins list are enabled. */
  allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
  /** If the allowed ips list are enabled. */
  allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Delete a connection from the database. */
export type ConnectionDeleteMutationPayload = {
  __typename?: 'ConnectionDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  connection?: Maybe<ConnectionType>;
};

export type ConnectionDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Validate that the sheet URL is valid. */
export type SheetValidateUrlMutationPayload = {
  __typename?: 'SheetValidateUrlMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the url is valid or not. */
  isValid?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SheetValidateUrlMutationInput = {
  /** The sheet URL to validate. */
  url: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Create a new sheet. */
export type SheetCreateMutationPayload = {
  __typename?: 'SheetCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  sheet?: Maybe<SheetType>;
};

export type SheetCreateMutationInput = {
  /** The sheet's url. */
  url: Scalars['String'];
  /** The sheet's name. */
  name?: Maybe<Scalars['String']>;
  /** The sheet's owner. */
  user?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Update a sheet in the database. */
export type SheetUpdateMutationPayload = {
  __typename?: 'SheetUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  sheet?: Maybe<SheetType>;
};

export type SheetUpdateMutationInput = {
  /** The sheet's name. */
  name?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** If the api is starred. */
  starred?: Maybe<Scalars['Boolean']>;
  /** If the api key is enabled. */
  apiKeyEnabled?: Maybe<Scalars['Boolean']>;
  /** A key to protect this sheet's endpoint. */
  apiKey?: Maybe<Scalars['String']>;
  /** List of methods allowed for this sheet. */
  allowedMethods?: Maybe<Array<Scalars['String']>>;
  /** If the allowed origins list are enabled. */
  allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
  /** List of origins allowed for this sheet. */
  allowedOrigins?: Maybe<Array<Scalars['String']>>;
  /** If the allowed ips list are enabled. */
  allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
  /** List of ips allowed for this sheet. */
  allowedIps?: Maybe<Array<Scalars['String']>>;
  /** For how long should we cache the content (in seconds). */
  ttl?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Delete the sheet from the database. */
export type SheetDeleteMutationPayload = {
  __typename?: 'SheetDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  sheet?: Maybe<SheetType>;
};

export type SheetDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Toggle the starred state of the sheety. */
export type SheetStarToggleMutationPayload = {
  __typename?: 'SheetStarToggleMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  sheet?: Maybe<SheetType>;
};

export type SheetStarToggleMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebTokenPayload = {
  __typename?: 'ObtainJSONWebTokenPayload';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type ObtainJsonWebTokenInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  password: Scalars['String'];
};

export type VerifyPayload = {
  __typename?: 'VerifyPayload';
  payload: Scalars['GenericScalar'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type VerifyInput = {
  token?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RefreshPayload = {
  __typename?: 'RefreshPayload';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type RefreshInput = {
  token?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Create a new user. */
export type UserCreateMutationPayload = {
  __typename?: 'UserCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserCreateMutationInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  rePassword: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Activate a user's registration. */
export type UserActivateMutationPayload = {
  __typename?: 'UserActivateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserActivateMutationInput = {
  token: Scalars['String'];
  uid: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Request to reset a user's password. */
export type UserResetPasswordMutationPayload = {
  __typename?: 'UserResetPasswordMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserResetPasswordMutationInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Confirm the request to reset a user's password. */
export type UserResetPasswordConfirmMutationPayload = {
  __typename?: 'UserResetPasswordConfirmMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserResetPasswordConfirmMutationInput = {
  uid: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
  rePassword: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Mutation that logs the user out. */
export type UserLogoutMutationPayload = {
  __typename?: 'UserLogoutMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserLogoutMutationInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Subscribe the user to the given subscription. */
export type UserSubscriptionCreateMutationPayload = {
  __typename?: 'UserSubscriptionCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

export type UserSubscriptionCreateMutationInput = {
  /** The paypal subscription ID. */
  paypalSubscriptionId: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Subscribe the user to the given subscription. */
export type UserSubscriptionChangeMutationPayload = {
  __typename?: 'UserSubscriptionChangeMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the user needs to approve this change. */
  needApproval: Scalars['Boolean'];
  /** An approval link. */
  approvalLink?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

export type UserSubscriptionChangeMutationInput = {
  /** The new paypal plan ID. */
  subscriptionPlan: Scalars['ID'];
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Confirm the subscription change for the user. */
export type UserSubscriptionChangeConfirmMutationPayload = {
  __typename?: 'UserSubscriptionChangeConfirmMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

export type UserSubscriptionChangeConfirmMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SubscriptionFieldsFragment = { __typename?: 'SubscriptionPlanType' } & Pick<
  SubscriptionPlanType,
  | 'id'
  | 'code'
  | 'name'
  | 'description'
  | 'price'
  | 'maxConnections'
  | 'maxRequests'
  | 'logRetention'
  | 'chartsTier'
  | 'connectionsAllowed'
  | 'methodsSupport'
  | 'whitelistSupport'
  | 'prioritarySupport'
  | 'advancedQueries'
  | 'apiKey'
  | 'apiKeyRenewable'
  | 'customizableCache'
  | 'paypalPlanId'
  | 'stripeProductId'
  | 'mostPopular'
  | 'hasTrial'
> & {
    nextTier?: Maybe<{ __typename?: 'SubscriptionPlanType' } & Pick<SubscriptionPlanType, 'id'>>;
  };

export type MeFieldsFragment = { __typename?: 'UserType' } & Pick<
  UserType,
  | 'username'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'isActive'
  | 'dateJoined'
  | 'id'
  | 'hitsCached'
  | 'hitsCachedTotal'
  | 'stripeSubscriptionId'
  | 'stripeCustomerId'
  | 'subscriptionFailureFlag'
  | 'pk'
  | 'limit'
  | 'remaining'
  | 'hash'
  | 'lastLogin'
> & { subscription: { __typename?: 'SubscriptionPlanType' } & SubscriptionFieldsFragment };

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
  me?: Maybe<{ __typename?: 'UserType' } & MeFieldsFragment>;
};

export type UserLogoutMutationVariables = Exact<{ [key: string]: never }>;

export type UserLogoutMutation = { __typename?: 'Mutation' } & {
  userLogout?: Maybe<
    { __typename?: 'UserLogoutMutationPayload' } & Pick<UserLogoutMutationPayload, 'success'> & {
        errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & ErrorFieldsFragment>>;
      }
  >;
};

export type AccountUsageQueryVariables = Exact<{ [key: string]: never }>;

export type AccountUsageQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'UserType' } & Pick<UserType, 'hitsCached' | 'limit'> & {
        subscription: { __typename?: 'SubscriptionPlanType' } & Pick<
          SubscriptionPlanType,
          'id' | 'maxConnections' | 'paypalPlanId' | 'stripeProductId'
        > & {
            nextTier?: Maybe<
              { __typename?: 'SubscriptionPlanType' } & Pick<SubscriptionPlanType, 'id'>
            >;
          };
      }
  >;
  connections?: Maybe<
    { __typename?: 'ConnectionTypeConnection' } & Pick<ConnectionTypeConnection, 'totalCount'>
  >;
};

export type ErrorFieldsFragment = { __typename?: 'MutationErrorType' } & Pick<
  MutationErrorType,
  'field' | 'message'
>;

export type PageInfoFieldsFragment = { __typename?: 'PageInfo' } & Pick<
  PageInfo,
  'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
>;

export type ConnectionFieldsFragment = { __typename?: 'ConnectionType' } & Pick<
  ConnectionType,
  | 'id'
  | 'url'
  | 'kind'
  | 'name'
  | 'apiUrl'
  | 'createdAt'
  | 'updatedAt'
  | 'hitsCached'
  | 'hitsCachedTotal'
  | 'lastHittedAt'
  | 'totalRows'
  | 'remaining'
  | 'starred'
  | 'ttl'
  | 'allowedMethods'
  | 'allowedIpsEnabled'
  | 'allowedIps'
  | 'allowedOriginsEnabled'
  | 'allowedOrigins'
  | 'apiKeyEnabled'
  | 'apiKey'
>;

export type ConnectionHitByPeriodFieldsFragment = {
  __typename?: 'ConnectionHitsByPeriodType';
} & Pick<ConnectionHitsByPeriodType, 'hits' | 'period' | 'method'>;

export type ConnectionHitFieldsFragment = { __typename?: 'ConnectionHitType' } & Pick<
  ConnectionHitType,
  'id' | 'createdAt' | 'ipAddress' | 'method' | 'path' | 'params' | 'data' | 'kind'
> & { connection?: Maybe<{ __typename?: 'ConnectionType' } & Pick<ConnectionType, 'name'>> };

export type ConnectionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  after?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  starred?: Maybe<Scalars['Boolean']>;
}>;

export type ConnectionsQuery = { __typename?: 'Query' } & {
  connections?: Maybe<
    { __typename?: 'ConnectionTypeConnection' } & Pick<ConnectionTypeConnection, 'totalCount'> & {
        edges: Array<
          Maybe<
            { __typename?: 'ConnectionTypeEdge' } & {
              node?: Maybe<{ __typename?: 'ConnectionType' } & ConnectionFieldsFragment>;
            }
          >
        >;
      }
  >;
};

export type ConnectionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ConnectionQuery = { __typename?: 'Query' } & {
  connection?: Maybe<{ __typename?: 'ConnectionType' } & ConnectionFieldsFragment>;
};

export type ConnectionValidateMutationVariables = Exact<{
  kind: ConnectionKind;
  url: Scalars['String'];
}>;

export type ConnectionValidateMutation = { __typename?: 'Mutation' } & {
  connectionValidateUrl?: Maybe<
    { __typename?: 'ConnectionValidateUrlMutationPayload' } & Pick<
      ConnectionValidateUrlMutationPayload,
      'isValid'
    > & { errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & ErrorFieldsFragment>> }
  >;
};

export type ConnectionCreateMutationVariables = Exact<{
  input: ConnectionCreateMutationInput;
}>;

export type ConnectionCreateMutation = { __typename?: 'Mutation' } & {
  connectionCreate?: Maybe<
    { __typename?: 'ConnectionCreateMutationPayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & ErrorFieldsFragment>>;
      connection?: Maybe<{ __typename?: 'ConnectionType' } & ConnectionFieldsFragment>;
    }
  >;
};

export type ConnectionUpdateMutationVariables = Exact<{
  input: ConnectionUpdateMutationInput;
}>;

export type ConnectionUpdateMutation = { __typename?: 'Mutation' } & {
  connectionUpdate?: Maybe<
    { __typename?: 'ConnectionUpdateMutationPayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & ErrorFieldsFragment>>;
      connection?: Maybe<{ __typename?: 'ConnectionType' } & ConnectionFieldsFragment>;
    }
  >;
};

export type ConnectionDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ConnectionDeleteMutation = { __typename?: 'Mutation' } & {
  connectionDelete?: Maybe<
    { __typename?: 'ConnectionDeleteMutationPayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & ErrorFieldsFragment>>;
      connection?: Maybe<{ __typename?: 'ConnectionType' } & ConnectionFieldsFragment>;
    }
  >;
};

export type ConnectionHitsByPeriodQueryVariables = Exact<{
  start: Scalars['Date'];
  end: Scalars['Date'];
  periodType: PeriodType;
  connection?: Maybe<Scalars['ID']>;
  groupByMethod?: Maybe<Scalars['Boolean']>;
  tz?: Maybe<Scalars['String']>;
}>;

export type ConnectionHitsByPeriodQuery = { __typename?: 'Query' } & {
  connectionHitsByPeriod?: Maybe<
    Array<{ __typename?: 'ConnectionHitsByPeriodType' } & ConnectionHitByPeriodFieldsFragment>
  >;
};

export type ConnectionHitsQueryVariables = Exact<{
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  method?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  connection?: Maybe<Scalars['ID']>;
}>;

export type ConnectionHitsQuery = { __typename?: 'Query' } & {
  connectionHits?: Maybe<
    { __typename?: 'ConnectionHitTypeConnection' } & Pick<
      ConnectionHitTypeConnection,
      'totalCount'
    > & {
        pageInfo: { __typename?: 'PageInfo' } & PageInfoFieldsFragment;
        edges: Array<
          Maybe<
            { __typename?: 'ConnectionHitTypeEdge' } & {
              node?: Maybe<{ __typename?: 'ConnectionHitType' } & ConnectionHitFieldsFragment>;
            }
          >
        >;
      }
  >;
};

export type CreateFreeSheetMutationVariables = Exact<{
  url: Scalars['String'];
  user?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
}>;

export type CreateFreeSheetMutation = { __typename?: 'Mutation' } & {
  sheetCreate?: Maybe<
    { __typename?: 'SheetCreateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      sheet?: Maybe<
        { __typename?: 'SheetType' } & Pick<
          SheetType,
          | 'id'
          | 'starred'
          | 'pk'
          | 'name'
          | 'url'
          | 'createdAt'
          | 'lastHittedAt'
          | 'hitsCachedTotal'
          | 'totalRows'
          | 'ttl'
          | 'allowedMethods'
          | 'apiKeyEnabled'
          | 'apiKey'
          | 'allowedIpsEnabled'
          | 'allowedIps'
          | 'allowedOriginsEnabled'
          | 'allowedOrigins'
        >
      >;
    }
  >;
};

export type DeleteSheetMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteSheetMutation = { __typename?: 'Mutation' } & {
  sheetDelete?: Maybe<
    { __typename?: 'SheetDeleteMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      sheet?: Maybe<{ __typename?: 'SheetType' } & Pick<SheetType, 'id'>>;
    }
  >;
};

export type GetSheetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSheetsQuery = { __typename?: 'Query' } & {
  sheets?: Maybe<
    { __typename?: 'SheetTypeConnection' } & Pick<SheetTypeConnection, 'totalCount'> & {
        edges: Array<
          Maybe<
            { __typename?: 'SheetTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'SheetType' } & Pick<
                  SheetType,
                  | 'id'
                  | 'starred'
                  | 'pk'
                  | 'name'
                  | 'url'
                  | 'createdAt'
                  | 'lastHittedAt'
                  | 'hitsCachedTotal'
                  | 'totalRows'
                  | 'ttl'
                  | 'allowedMethods'
                  | 'apiKeyEnabled'
                  | 'apiKey'
                  | 'allowedIpsEnabled'
                  | 'allowedIps'
                  | 'allowedOriginsEnabled'
                  | 'allowedOrigins'
                >
              >;
            }
          >
        >;
      }
  >;
};

export type LatestHitsQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;

export type LatestHitsQuery = { __typename?: 'Query' } & {
  sheetHits?: Maybe<
    { __typename?: 'SheetHitTypeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'SheetHitTypeEdge' } & {
            node?: Maybe<
              { __typename?: 'SheetHitType' } & Pick<
                SheetHitType,
                | 'ipAddress'
                | 'method'
                | 'path'
                | 'id'
                | 'data'
                | 'params'
                | 'createdAt'
                | 'updatedAt'
              > & { connection: { __typename?: 'SheetType' } & Pick<SheetType, 'name' | 'id'> }
            >;
          }
        >
      >;
    }
  >;
};

export type SheetHitsByPeriodQueryVariables = Exact<{
  start: Scalars['Date'];
  end: Scalars['Date'];
  periodType: PeriodType;
  groupByMethod?: Maybe<Scalars['Boolean']>;
  sheet?: Maybe<Scalars['ID']>;
  timezone?: Maybe<Scalars['String']>;
}>;

export type SheetHitsByPeriodQuery = { __typename?: 'Query' } & {
  sheetHitsByPeriod?: Maybe<
    Array<
      { __typename?: 'ConnectionHitsByPeriodType' } & Pick<
        ConnectionHitsByPeriodType,
        'hits' | 'period' | 'method'
      >
    >
  >;
};

export type SheetPublicQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type SheetPublicQuery = { __typename?: 'Query' } & {
  sheetPublic?: Maybe<
    { __typename?: 'SheetPublicType' } & Pick<
      SheetPublicType,
      'url' | 'hitsCached' | 'limit' | 'remaining'
    >
  >;
};

export type StarToggleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StarToggleMutation = { __typename?: 'Mutation' } & {
  sheetStarToggle?: Maybe<
    { __typename?: 'SheetStarToggleMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      sheet?: Maybe<{ __typename?: 'SheetType' } & Pick<SheetType, 'id' | 'starred'>>;
    }
  >;
};

export type SubscriptionPlansFieldsFragment = { __typename?: 'SubscriptionPlanType' } & Pick<
  SubscriptionPlanType,
  | 'id'
  | 'code'
  | 'name'
  | 'price'
  | 'description'
  | 'maxConnections'
  | 'maxRequests'
  | 'logRetention'
  | 'chartsTier'
  | 'connectionsAllowed'
  | 'methodsSupport'
  | 'whitelistSupport'
  | 'prioritarySupport'
  | 'advancedQueries'
  | 'apiKey'
  | 'apiKeyRenewable'
  | 'customizableCache'
  | 'paypalPlanId'
  | 'stripeProductId'
  | 'mostPopular'
  | 'hasTrial'
>;

export type SubscriptionPlansQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionPlansQuery = { __typename?: 'Query' } & {
  subscriptionPlans?: Maybe<
    Array<{ __typename?: 'SubscriptionPlanType' } & SubscriptionPlansFieldsFragment>
  >;
};

export type UserSubscriptionCreateMutationVariables = Exact<{
  paypalSubscriptionId: Scalars['String'];
  userId: Scalars['ID'];
}>;

export type UserSubscriptionCreateMutation = { __typename?: 'Mutation' } & {
  userSubscriptionCreate?: Maybe<
    { __typename?: 'UserSubscriptionCreateMutationPayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & ErrorFieldsFragment>>;
      user?: Maybe<
        { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
            subscription: { __typename?: 'SubscriptionPlanType' } & SubscriptionFieldsFragment;
          }
      >;
    }
  >;
};

export type UserSubscriptionChangeMutationVariables = Exact<{
  subscriptionPlan: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type UserStripeCheckoutSessionCreateMutation = { __typename?: 'Mutation' } & {
  userStripeCheckoutSessionCreate?: Maybe<
    { __typename?: 'UserStripeCheckoutSessionCreateMutationPayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & ErrorFieldsFragment>>;
      sessionId?: Scalars['String'];
    }
  >;
};

export type UserStripeCheckoutSessionCreateMutationVariables = Exact<{
  planId: Scalars['String'];
  userId: Scalars['ID'];
}>;

//SubscriptionCheckout
export type UserStripeSubscriptionCheckoutSessionCreateMutation = { __typename?: 'Mutation' } & {
  userStripeSubscriptionCheckoutSessionCreate?: Maybe<
    { __typename?: 'UserStripeSubscriptionCheckoutSessionCreateMutationPayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & ErrorFieldsFragment>>;
      sessionId?: Scalars['String'];
    }
  >;
};

//SubscriptionCheckout
export type UserStripeSubscriptionCheckoutSessionCreateMutationVariables = Exact<{
  stripeProductId: Scalars['String'];
  userId: Scalars['ID'];
}>;

export type UserSubscriptionChangeMutation = { __typename?: 'Mutation' } & {
  userSubscriptionChange?: Maybe<
    { __typename?: 'UserSubscriptionChangeMutationPayload' } & Pick<
      UserSubscriptionChangeMutationPayload,
      'needApproval' | 'approvalLink'
    > & {
        errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & ErrorFieldsFragment>>;
        user?: Maybe<
          { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
              subscription: { __typename?: 'SubscriptionPlanType' } & SubscriptionFieldsFragment;
            }
        >;
      }
  >;
};

export type UserSubscriptionChangeConfirmMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserSubscriptionChangeConfirmMutation = { __typename?: 'Mutation' } & {
  userSubscriptionChangeConfirm?: Maybe<
    { __typename?: 'UserSubscriptionChangeConfirmMutationPayload' } & {
      errors?: Maybe<Array<{ __typename?: 'MutationErrorType' } & ErrorFieldsFragment>>;
      user?: Maybe<
        { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
            subscription: { __typename?: 'SubscriptionPlanType' } & SubscriptionFieldsFragment;
          }
      >;
    }
  >;
};

export type SubscriptionPlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SubscriptionPlanQuery = { __typename?: 'Query' } & {
  subscriptionPlan?: Maybe<{ __typename?: 'SubscriptionPlanType' } & SubscriptionFieldsFragment>;
};

export type UpdateSheetMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  starred: Scalars['Boolean'];
  apiKeyEnabled: Scalars['Boolean'];
  apiKey?: Maybe<Scalars['String']>;
  allowedMethods?: Maybe<Array<Scalars['String']>>;
  allowedOriginsEnabled: Scalars['Boolean'];
  allowedOrigins?: Maybe<Array<Scalars['String']>>;
  allowedIpsEnabled: Scalars['Boolean'];
  allowedIps?: Maybe<Array<Scalars['String']>>;
  ttl?: Maybe<Scalars['Int']>;
}>;

export type UpdateSheetMutation = { __typename?: 'Mutation' } & {
  sheetUpdate?: Maybe<
    { __typename?: 'SheetUpdateMutationPayload' } & {
      errors?: Maybe<
        Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'field' | 'message'>>
      >;
      sheet?: Maybe<
        { __typename?: 'SheetType' } & Pick<
          SheetType,
          | 'id'
          | 'name'
          | 'starred'
          | 'apiKeyEnabled'
          | 'apiKey'
          | 'allowedMethods'
          | 'allowedOriginsEnabled'
          | 'allowedOrigins'
          | 'allowedIpsEnabled'
          | 'allowedIps'
          | 'ttl'
        >
      >;
    }
  >;
};

export type ValidateUrlMutationVariables = Exact<{
  url: Scalars['String'];
}>;

export type ValidateUrlMutation = { __typename?: 'Mutation' } & {
  sheetValidateUrl?: Maybe<
    { __typename?: 'SheetValidateUrlMutationPayload' } & Pick<
      SheetValidateUrlMutationPayload,
      'isValid'
    > & {
        errors?: Maybe<
          Array<{ __typename?: 'MutationErrorType' } & Pick<MutationErrorType, 'message'>>
        >;
      }
  >;
};

export const SubscriptionFieldsFragmentDoc = gql`
  fragment subscriptionFields on SubscriptionPlanType {
    id
    code
    name
    description
    price
    maxConnections
    maxRequests
    logRetention
    chartsTier
    connectionsAllowed
    methodsSupport
    whitelistSupport
    prioritarySupport
    advancedQueries
    apiKey
    apiKeyRenewable
    customizableCache
    paypalPlanId
    stripeProductId
    mostPopular
    hasTrial
    nextTier {
      id
    }
  }
`;
export const MeFieldsFragmentDoc = gql`
  fragment meFields on UserType {
    username
    firstName
    lastName
    email
    isActive
    dateJoined
    id
    hitsCached
    hitsCachedTotal
    stripeSubscriptionId
    stripeCustomerId
    subscriptionFailureFlag
    pk
    limit
    remaining
    hash
    lastLogin
    subscription {
      ...subscriptionFields
    }
  }
  ${SubscriptionFieldsFragmentDoc}
`;
export const ErrorFieldsFragmentDoc = gql`
  fragment errorFields on MutationErrorType {
    field
    message
  }
`;
export const PageInfoFieldsFragmentDoc = gql`
  fragment pageInfoFields on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
export const ConnectionFieldsFragmentDoc = gql`
  fragment connectionFields on ConnectionType {
    id
    url
    kind
    name
    apiUrl
    createdAt
    updatedAt
    hitsCached
    hitsCachedTotal
    lastHittedAt
    totalRows
    remaining
    starred
    ttl
    allowedMethods
    allowedIpsEnabled
    allowedIps
    allowedOriginsEnabled
    allowedOrigins
    apiKeyEnabled
    apiKey
  }
`;
export const ConnectionHitByPeriodFieldsFragmentDoc = gql`
  fragment connectionHitByPeriodFields on ConnectionHitsByPeriodType {
    hits
    period
    method
  }
`;
export const ConnectionHitFieldsFragmentDoc = gql`
  fragment connectionHitFields on ConnectionHitType {
    id
    createdAt
    ipAddress
    method
    path
    params
    data
    kind
    connection {
      name
    }
  }
`;
export const SubscriptionPlansFieldsFragmentDoc = gql`
  fragment subscriptionPlansFields on SubscriptionPlanType {
    id
    code
    name
    price
    description
    maxConnections
    maxRequests
    logRetention
    chartsTier
    connectionsAllowed
    methodsSupport
    whitelistSupport
    prioritarySupport
    advancedQueries
    apiKey
    apiKeyRenewable
    customizableCache
    paypalPlanId
    stripeProductId
    mostPopular
    hasTrial
  }
`;
export const MeDocument = gql`
  query Me {
    me {
      ...meFields
    }
  }
  ${MeFieldsFragmentDoc}
`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserLogoutDocument = gql`
  mutation userLogout {
    userLogout(input: {}) {
      errors {
        ...errorFields
      }
      success
    }
  }
  ${ErrorFieldsFragmentDoc}
`;
export type UserLogoutMutationFn = Apollo.MutationFunction<
  UserLogoutMutation,
  UserLogoutMutationVariables
>;
export function useUserLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<UserLogoutMutation, UserLogoutMutationVariables>
) {
  return Apollo.useMutation<UserLogoutMutation, UserLogoutMutationVariables>(
    UserLogoutDocument,
    baseOptions
  );
}
export type UserLogoutMutationHookResult = ReturnType<typeof useUserLogoutMutation>;
export type UserLogoutMutationResult = Apollo.MutationResult<UserLogoutMutation>;
export type UserLogoutMutationOptions = Apollo.BaseMutationOptions<
  UserLogoutMutation,
  UserLogoutMutationVariables
>;
export const AccountUsageDocument = gql`
  query AccountUsage {
    me {
      hitsCached
      limit
      subscription {
        id
        maxConnections
        paypalPlanId
        stripeProductId
        nextTier {
          id
        }
      }
    }
    connections {
      totalCount
    }
  }
`;
export function useAccountUsageQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountUsageQuery, AccountUsageQueryVariables>
) {
  return Apollo.useQuery<AccountUsageQuery, AccountUsageQueryVariables>(
    AccountUsageDocument,
    baseOptions
  );
}
export function useAccountUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountUsageQuery, AccountUsageQueryVariables>
) {
  return Apollo.useLazyQuery<AccountUsageQuery, AccountUsageQueryVariables>(
    AccountUsageDocument,
    baseOptions
  );
}
export type AccountUsageQueryHookResult = ReturnType<typeof useAccountUsageQuery>;
export type AccountUsageLazyQueryHookResult = ReturnType<typeof useAccountUsageLazyQuery>;
export type AccountUsageQueryResult = Apollo.QueryResult<
  AccountUsageQuery,
  AccountUsageQueryVariables
>;
export const ConnectionsDocument = gql`
  query Connections(
    $first: Int
    $orderby: [String]
    $after: String
    $kind: String
    $search: String
    $starred: Boolean
  ) {
    connections(
      first: $first
      orderby: $orderby
      after: $after
      kind: $kind
      search: $search
      starred: $starred
    ) {
      totalCount
      edges {
        node {
          ...connectionFields
        }
      }
    }
  }
  ${ConnectionFieldsFragmentDoc}
`;
export function useConnectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables>
) {
  return Apollo.useQuery<ConnectionsQuery, ConnectionsQueryVariables>(
    ConnectionsDocument,
    baseOptions
  );
}
export function useConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables>
) {
  return Apollo.useLazyQuery<ConnectionsQuery, ConnectionsQueryVariables>(
    ConnectionsDocument,
    baseOptions
  );
}
export type ConnectionsQueryHookResult = ReturnType<typeof useConnectionsQuery>;
export type ConnectionsLazyQueryHookResult = ReturnType<typeof useConnectionsLazyQuery>;
export type ConnectionsQueryResult = Apollo.QueryResult<
  ConnectionsQuery,
  ConnectionsQueryVariables
>;
export const ConnectionDocument = gql`
  query Connection($id: ID!) {
    connection(id: $id) {
      ...connectionFields
    }
  }
  ${ConnectionFieldsFragmentDoc}
`;
export function useConnectionQuery(
  baseOptions?: Apollo.QueryHookOptions<ConnectionQuery, ConnectionQueryVariables>
) {
  return Apollo.useQuery<ConnectionQuery, ConnectionQueryVariables>(
    ConnectionDocument,
    baseOptions
  );
}
export function useConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConnectionQuery, ConnectionQueryVariables>
) {
  return Apollo.useLazyQuery<ConnectionQuery, ConnectionQueryVariables>(
    ConnectionDocument,
    baseOptions
  );
}
export type ConnectionQueryHookResult = ReturnType<typeof useConnectionQuery>;
export type ConnectionLazyQueryHookResult = ReturnType<typeof useConnectionLazyQuery>;
export type ConnectionQueryResult = Apollo.QueryResult<ConnectionQuery, ConnectionQueryVariables>;
export const ConnectionValidateDocument = gql`
  mutation ConnectionValidate($kind: ConnectionKind!, $url: String!) {
    connectionValidateUrl(input: { kind: $kind, url: $url }) {
      errors {
        ...errorFields
      }
      isValid
    }
  }
  ${ErrorFieldsFragmentDoc}
`;
export type ConnectionValidateMutationFn = Apollo.MutationFunction<
  ConnectionValidateMutation,
  ConnectionValidateMutationVariables
>;
export function useConnectionValidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectionValidateMutation,
    ConnectionValidateMutationVariables
  >
) {
  return Apollo.useMutation<ConnectionValidateMutation, ConnectionValidateMutationVariables>(
    ConnectionValidateDocument,
    baseOptions
  );
}
export type ConnectionValidateMutationHookResult = ReturnType<typeof useConnectionValidateMutation>;
export type ConnectionValidateMutationResult = Apollo.MutationResult<ConnectionValidateMutation>;
export type ConnectionValidateMutationOptions = Apollo.BaseMutationOptions<
  ConnectionValidateMutation,
  ConnectionValidateMutationVariables
>;
export const ConnectionCreateDocument = gql`
  mutation ConnectionCreate($input: ConnectionCreateMutationInput!) {
    connectionCreate(input: $input) {
      errors {
        ...errorFields
      }
      connection {
        ...connectionFields
      }
    }
  }
  ${ErrorFieldsFragmentDoc}
  ${ConnectionFieldsFragmentDoc}
`;
export type ConnectionCreateMutationFn = Apollo.MutationFunction<
  ConnectionCreateMutation,
  ConnectionCreateMutationVariables
>;
export function useConnectionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectionCreateMutation,
    ConnectionCreateMutationVariables
  >
) {
  return Apollo.useMutation<ConnectionCreateMutation, ConnectionCreateMutationVariables>(
    ConnectionCreateDocument,
    baseOptions
  );
}
export type ConnectionCreateMutationHookResult = ReturnType<typeof useConnectionCreateMutation>;
export type ConnectionCreateMutationResult = Apollo.MutationResult<ConnectionCreateMutation>;
export type ConnectionCreateMutationOptions = Apollo.BaseMutationOptions<
  ConnectionCreateMutation,
  ConnectionCreateMutationVariables
>;
export const ConnectionUpdateDocument = gql`
  mutation ConnectionUpdate($input: ConnectionUpdateMutationInput!) {
    connectionUpdate(input: $input) {
      errors {
        ...errorFields
      }
      connection {
        ...connectionFields
      }
    }
  }
  ${ErrorFieldsFragmentDoc}
  ${ConnectionFieldsFragmentDoc}
`;
export type ConnectionUpdateMutationFn = Apollo.MutationFunction<
  ConnectionUpdateMutation,
  ConnectionUpdateMutationVariables
>;
export function useConnectionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectionUpdateMutation,
    ConnectionUpdateMutationVariables
  >
) {
  return Apollo.useMutation<ConnectionUpdateMutation, ConnectionUpdateMutationVariables>(
    ConnectionUpdateDocument,
    baseOptions
  );
}
export type ConnectionUpdateMutationHookResult = ReturnType<typeof useConnectionUpdateMutation>;
export type ConnectionUpdateMutationResult = Apollo.MutationResult<ConnectionUpdateMutation>;
export type ConnectionUpdateMutationOptions = Apollo.BaseMutationOptions<
  ConnectionUpdateMutation,
  ConnectionUpdateMutationVariables
>;
export const ConnectionDeleteDocument = gql`
  mutation ConnectionDelete($id: ID!) {
    connectionDelete(input: { id: $id }) {
      errors {
        ...errorFields
      }
      connection {
        ...connectionFields
      }
    }
  }
  ${ErrorFieldsFragmentDoc}
  ${ConnectionFieldsFragmentDoc}
`;
export type ConnectionDeleteMutationFn = Apollo.MutationFunction<
  ConnectionDeleteMutation,
  ConnectionDeleteMutationVariables
>;
export function useConnectionDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectionDeleteMutation,
    ConnectionDeleteMutationVariables
  >
) {
  return Apollo.useMutation<ConnectionDeleteMutation, ConnectionDeleteMutationVariables>(
    ConnectionDeleteDocument,
    baseOptions
  );
}
export type ConnectionDeleteMutationHookResult = ReturnType<typeof useConnectionDeleteMutation>;
export type ConnectionDeleteMutationResult = Apollo.MutationResult<ConnectionDeleteMutation>;
export type ConnectionDeleteMutationOptions = Apollo.BaseMutationOptions<
  ConnectionDeleteMutation,
  ConnectionDeleteMutationVariables
>;
export const ConnectionHitsByPeriodDocument = gql`
  query ConnectionHitsByPeriod(
    $start: Date!
    $end: Date!
    $periodType: PeriodType!
    $connection: ID
    $groupByMethod: Boolean
    $tz: String
  ) {
    connectionHitsByPeriod(
      start: $start
      end: $end
      periodType: $periodType
      connection: $connection
      groupByMethod: $groupByMethod
      tz: $tz
    ) {
      ...connectionHitByPeriodFields
    }
  }
  ${ConnectionHitByPeriodFieldsFragmentDoc}
`;
export function useConnectionHitsByPeriodQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ConnectionHitsByPeriodQuery,
    ConnectionHitsByPeriodQueryVariables
  >
) {
  return Apollo.useQuery<ConnectionHitsByPeriodQuery, ConnectionHitsByPeriodQueryVariables>(
    ConnectionHitsByPeriodDocument,
    baseOptions
  );
}
export function useConnectionHitsByPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectionHitsByPeriodQuery,
    ConnectionHitsByPeriodQueryVariables
  >
) {
  return Apollo.useLazyQuery<ConnectionHitsByPeriodQuery, ConnectionHitsByPeriodQueryVariables>(
    ConnectionHitsByPeriodDocument,
    baseOptions
  );
}
export type ConnectionHitsByPeriodQueryHookResult = ReturnType<
  typeof useConnectionHitsByPeriodQuery
>;
export type ConnectionHitsByPeriodLazyQueryHookResult = ReturnType<
  typeof useConnectionHitsByPeriodLazyQuery
>;
export type ConnectionHitsByPeriodQueryResult = Apollo.QueryResult<
  ConnectionHitsByPeriodQuery,
  ConnectionHitsByPeriodQueryVariables
>;
export const ConnectionHitsDocument = gql`
  query ConnectionHits(
    $orderby: [String]
    $after: String
    $first: Int = 100
    $start: DateTime
    $end: DateTime
    $method: String
    $source: String
    $connection: ID
  ) {
    connectionHits(
      orderby: $orderby
      after: $after
      first: $first
      createdAt_Gte: $start
      createdAt_Lte: $end
      method_Iexact: $method
      source: $source
      connection: $connection
    ) {
      totalCount
      pageInfo {
        ...pageInfoFields
      }
      edges {
        node {
          ...connectionHitFields
        }
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
  ${ConnectionHitFieldsFragmentDoc}
`;
export function useConnectionHitsQuery(
  baseOptions?: Apollo.QueryHookOptions<ConnectionHitsQuery, ConnectionHitsQueryVariables>
) {
  return Apollo.useQuery<ConnectionHitsQuery, ConnectionHitsQueryVariables>(
    ConnectionHitsDocument,
    baseOptions
  );
}
export function useConnectionHitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConnectionHitsQuery, ConnectionHitsQueryVariables>
) {
  return Apollo.useLazyQuery<ConnectionHitsQuery, ConnectionHitsQueryVariables>(
    ConnectionHitsDocument,
    baseOptions
  );
}
export type ConnectionHitsQueryHookResult = ReturnType<typeof useConnectionHitsQuery>;
export type ConnectionHitsLazyQueryHookResult = ReturnType<typeof useConnectionHitsLazyQuery>;
export type ConnectionHitsQueryResult = Apollo.QueryResult<
  ConnectionHitsQuery,
  ConnectionHitsQueryVariables
>;
export const CreateFreeSheetDocument = gql`
  mutation createFreeSheet($url: String!, $user: ID, $name: String) {
    sheetCreate(input: { url: $url, user: $user, name: $name }) {
      errors {
        field
        message
      }
      sheet {
        id
        starred
        pk
        name
        url
        createdAt
        lastHittedAt
        hitsCachedTotal
        totalRows
        ttl
        allowedMethods
        apiKeyEnabled
        apiKey
        allowedIpsEnabled
        allowedIps
        allowedOriginsEnabled
        allowedOrigins
      }
    }
  }
`;
export type CreateFreeSheetMutationFn = Apollo.MutationFunction<
  CreateFreeSheetMutation,
  CreateFreeSheetMutationVariables
>;
export function useCreateFreeSheetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFreeSheetMutation,
    CreateFreeSheetMutationVariables
  >
) {
  return Apollo.useMutation<CreateFreeSheetMutation, CreateFreeSheetMutationVariables>(
    CreateFreeSheetDocument,
    baseOptions
  );
}
export type CreateFreeSheetMutationHookResult = ReturnType<typeof useCreateFreeSheetMutation>;
export type CreateFreeSheetMutationResult = Apollo.MutationResult<CreateFreeSheetMutation>;
export type CreateFreeSheetMutationOptions = Apollo.BaseMutationOptions<
  CreateFreeSheetMutation,
  CreateFreeSheetMutationVariables
>;
export const DeleteSheetDocument = gql`
  mutation deleteSheet($id: ID!) {
    sheetDelete(input: { id: $id }) {
      errors {
        field
        message
      }
      sheet {
        id
      }
    }
  }
`;
export type DeleteSheetMutationFn = Apollo.MutationFunction<
  DeleteSheetMutation,
  DeleteSheetMutationVariables
>;
export function useDeleteSheetMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSheetMutation, DeleteSheetMutationVariables>
) {
  return Apollo.useMutation<DeleteSheetMutation, DeleteSheetMutationVariables>(
    DeleteSheetDocument,
    baseOptions
  );
}
export type DeleteSheetMutationHookResult = ReturnType<typeof useDeleteSheetMutation>;
export type DeleteSheetMutationResult = Apollo.MutationResult<DeleteSheetMutation>;
export type DeleteSheetMutationOptions = Apollo.BaseMutationOptions<
  DeleteSheetMutation,
  DeleteSheetMutationVariables
>;
export const GetSheetsDocument = gql`
  query getSheets {
    sheets {
      edges {
        node {
          id
          starred
          pk
          name
          url
          createdAt
          lastHittedAt
          hitsCachedTotal
          totalRows
          ttl
          allowedMethods
          apiKeyEnabled
          apiKey
          allowedIpsEnabled
          allowedIps
          allowedOriginsEnabled
          allowedOrigins
        }
      }
      totalCount
    }
  }
`;
export function useGetSheetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSheetsQuery, GetSheetsQueryVariables>
) {
  return Apollo.useQuery<GetSheetsQuery, GetSheetsQueryVariables>(GetSheetsDocument, baseOptions);
}
export function useGetSheetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSheetsQuery, GetSheetsQueryVariables>
) {
  return Apollo.useLazyQuery<GetSheetsQuery, GetSheetsQueryVariables>(
    GetSheetsDocument,
    baseOptions
  );
}
export type GetSheetsQueryHookResult = ReturnType<typeof useGetSheetsQuery>;
export type GetSheetsLazyQueryHookResult = ReturnType<typeof useGetSheetsLazyQuery>;
export type GetSheetsQueryResult = Apollo.QueryResult<GetSheetsQuery, GetSheetsQueryVariables>;
export const LatestHitsDocument = gql`
  query LatestHits($limit: Int!) {
    sheetHits(first: $limit) {
      edges {
        node {
          ipAddress
          method
          path
          id
          data
          params
          createdAt
          updatedAt
          connection {
            name
            id
          }
        }
      }
    }
  }
`;
export function useLatestHitsQuery(
  baseOptions?: Apollo.QueryHookOptions<LatestHitsQuery, LatestHitsQueryVariables>
) {
  return Apollo.useQuery<LatestHitsQuery, LatestHitsQueryVariables>(
    LatestHitsDocument,
    baseOptions
  );
}
export function useLatestHitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LatestHitsQuery, LatestHitsQueryVariables>
) {
  return Apollo.useLazyQuery<LatestHitsQuery, LatestHitsQueryVariables>(
    LatestHitsDocument,
    baseOptions
  );
}
export type LatestHitsQueryHookResult = ReturnType<typeof useLatestHitsQuery>;
export type LatestHitsLazyQueryHookResult = ReturnType<typeof useLatestHitsLazyQuery>;
export type LatestHitsQueryResult = Apollo.QueryResult<LatestHitsQuery, LatestHitsQueryVariables>;
export const SheetHitsByPeriodDocument = gql`
  query sheetHitsByPeriod(
    $start: Date!
    $end: Date!
    $periodType: PeriodType!
    $groupByMethod: Boolean = false
    $sheet: ID
    $timezone: String
  ) {
    sheetHitsByPeriod(
      start: $start
      end: $end
      periodType: $periodType
      groupByMethod: $groupByMethod
      sheet: $sheet
      tz: $timezone
    ) {
      hits
      period
      method
    }
  }
`;
export function useSheetHitsByPeriodQuery(
  baseOptions?: Apollo.QueryHookOptions<SheetHitsByPeriodQuery, SheetHitsByPeriodQueryVariables>
) {
  return Apollo.useQuery<SheetHitsByPeriodQuery, SheetHitsByPeriodQueryVariables>(
    SheetHitsByPeriodDocument,
    baseOptions
  );
}
export function useSheetHitsByPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SheetHitsByPeriodQuery, SheetHitsByPeriodQueryVariables>
) {
  return Apollo.useLazyQuery<SheetHitsByPeriodQuery, SheetHitsByPeriodQueryVariables>(
    SheetHitsByPeriodDocument,
    baseOptions
  );
}
export type SheetHitsByPeriodQueryHookResult = ReturnType<typeof useSheetHitsByPeriodQuery>;
export type SheetHitsByPeriodLazyQueryHookResult = ReturnType<typeof useSheetHitsByPeriodLazyQuery>;
export type SheetHitsByPeriodQueryResult = Apollo.QueryResult<
  SheetHitsByPeriodQuery,
  SheetHitsByPeriodQueryVariables
>;
export const SheetPublicDocument = gql`
  query SheetPublic($id: UUID!) {
    sheetPublic(id: $id) {
      url
      hitsCached
      limit
      remaining
    }
  }
`;
export function useSheetPublicQuery(
  baseOptions?: Apollo.QueryHookOptions<SheetPublicQuery, SheetPublicQueryVariables>
) {
  return Apollo.useQuery<SheetPublicQuery, SheetPublicQueryVariables>(
    SheetPublicDocument,
    baseOptions
  );
}
export function useSheetPublicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SheetPublicQuery, SheetPublicQueryVariables>
) {
  return Apollo.useLazyQuery<SheetPublicQuery, SheetPublicQueryVariables>(
    SheetPublicDocument,
    baseOptions
  );
}
export type SheetPublicQueryHookResult = ReturnType<typeof useSheetPublicQuery>;
export type SheetPublicLazyQueryHookResult = ReturnType<typeof useSheetPublicLazyQuery>;
export type SheetPublicQueryResult = Apollo.QueryResult<
  SheetPublicQuery,
  SheetPublicQueryVariables
>;
export const StarToggleDocument = gql`
  mutation starToggle($id: ID!) {
    sheetStarToggle(input: { id: $id }) {
      errors {
        field
        message
      }
      sheet {
        id
        starred
      }
    }
  }
`;
export type StarToggleMutationFn = Apollo.MutationFunction<
  StarToggleMutation,
  StarToggleMutationVariables
>;
export function useStarToggleMutation(
  baseOptions?: Apollo.MutationHookOptions<StarToggleMutation, StarToggleMutationVariables>
) {
  return Apollo.useMutation<StarToggleMutation, StarToggleMutationVariables>(
    StarToggleDocument,
    baseOptions
  );
}
export type StarToggleMutationHookResult = ReturnType<typeof useStarToggleMutation>;
export type StarToggleMutationResult = Apollo.MutationResult<StarToggleMutation>;
export type StarToggleMutationOptions = Apollo.BaseMutationOptions<
  StarToggleMutation,
  StarToggleMutationVariables
>;
export const SubscriptionPlansDocument = gql`
  query SubscriptionPlans {
    subscriptionPlans {
      ...subscriptionPlansFields
    }
  }
  ${SubscriptionPlansFieldsFragmentDoc}
`;
export function useSubscriptionPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>
) {
  return Apollo.useQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(
    SubscriptionPlansDocument,
    baseOptions
  );
}
export function useSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>
) {
  return Apollo.useLazyQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(
    SubscriptionPlansDocument,
    baseOptions
  );
}
export type SubscriptionPlansQueryHookResult = ReturnType<typeof useSubscriptionPlansQuery>;
export type SubscriptionPlansLazyQueryHookResult = ReturnType<typeof useSubscriptionPlansLazyQuery>;
export type SubscriptionPlansQueryResult = Apollo.QueryResult<
  SubscriptionPlansQuery,
  SubscriptionPlansQueryVariables
>;
export const UserSubscriptionCreateDocument = gql`
  mutation UserSubscriptionCreate($paypalSubscriptionId: String!, $userId: ID!) {
    userSubscriptionCreate(input: { paypalSubscriptionId: $paypalSubscriptionId, id: $userId }) {
      errors {
        ...errorFields
      }
      user {
        id
        subscription {
          ...subscriptionFields
        }
      }
    }
  }
  ${ErrorFieldsFragmentDoc}
  ${SubscriptionFieldsFragmentDoc}
`;
export type UserSubscriptionCreateMutationFn = Apollo.MutationFunction<
  UserSubscriptionCreateMutation,
  UserSubscriptionCreateMutationVariables
>;
export function useUserSubscriptionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserSubscriptionCreateMutation,
    UserSubscriptionCreateMutationVariables
  >
) {
  return Apollo.useMutation<
    UserSubscriptionCreateMutation,
    UserSubscriptionCreateMutationVariables
  >(UserSubscriptionCreateDocument, baseOptions);
}
export type UserSubscriptionCreateMutationHookResult = ReturnType<
  typeof useUserSubscriptionCreateMutation
>;
export type UserSubscriptionCreateMutationResult = Apollo.MutationResult<
  UserSubscriptionCreateMutation
>;
export type UserSubscriptionCreateMutationOptions = Apollo.BaseMutationOptions<
  UserSubscriptionCreateMutation,
  UserSubscriptionCreateMutationVariables
>;
export const UserSubscriptionChangeDocument = gql`
  mutation UserSubscriptionChange($subscriptionPlan: ID!, $userId: ID!) {
    userSubscriptionChange(input: { subscriptionPlan: $subscriptionPlan, id: $userId }) {
      errors {
        ...errorFields
      }
      needApproval
      approvalLink
      user {
        id
        subscription {
          ...subscriptionFields
        }
      }
    }
  }
  ${ErrorFieldsFragmentDoc}
  ${SubscriptionFieldsFragmentDoc}
`;
export type UserSubscriptionChangeMutationFn = Apollo.MutationFunction<
  UserSubscriptionChangeMutation,
  UserSubscriptionChangeMutationVariables
>;
export function useUserSubscriptionChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserSubscriptionChangeMutation,
    UserSubscriptionChangeMutationVariables
  >
) {
  return Apollo.useMutation<
    UserSubscriptionChangeMutation,
    UserSubscriptionChangeMutationVariables
  >(UserSubscriptionChangeDocument, baseOptions);
}
export type UserSubscriptionChangeMutationHookResult = ReturnType<
  typeof useUserSubscriptionChangeMutation
>;
export type UserSubscriptionChangeMutationResult = Apollo.MutationResult<
  UserSubscriptionChangeMutation
>;
export type UserSubscriptionChangeMutationOptions = Apollo.BaseMutationOptions<
  UserSubscriptionChangeMutation,
  UserSubscriptionChangeMutationVariables
>;
export const UserSubscriptionChangeConfirmDocument = gql`
  mutation UserSubscriptionChangeConfirm($userId: ID!) {
    userSubscriptionChangeConfirm(input: { id: $userId }) {
      errors {
        ...errorFields
      }
      user {
        id
        subscription {
          ...subscriptionFields
        }
      }
    }
  }
  ${ErrorFieldsFragmentDoc}
  ${SubscriptionFieldsFragmentDoc}
`;
export type UserSubscriptionChangeConfirmMutationFn = Apollo.MutationFunction<
  UserSubscriptionChangeConfirmMutation,
  UserSubscriptionChangeConfirmMutationVariables
>;
export function useUserSubscriptionChangeConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserSubscriptionChangeConfirmMutation,
    UserSubscriptionChangeConfirmMutationVariables
  >
) {
  return Apollo.useMutation<
    UserSubscriptionChangeConfirmMutation,
    UserSubscriptionChangeConfirmMutationVariables
  >(UserSubscriptionChangeConfirmDocument, baseOptions);
}
export type UserSubscriptionChangeConfirmMutationHookResult = ReturnType<
  typeof useUserSubscriptionChangeConfirmMutation
>;
export type UserSubscriptionChangeConfirmMutationResult = Apollo.MutationResult<
  UserSubscriptionChangeConfirmMutation
>;
export type UserSubscriptionChangeConfirmMutationOptions = Apollo.BaseMutationOptions<
  UserSubscriptionChangeConfirmMutation,
  UserSubscriptionChangeConfirmMutationVariables
>;
export const SubscriptionPlanDocument = gql`
  query SubscriptionPlan($id: ID!) {
    subscriptionPlan(id: $id) {
      ...subscriptionFields
    }
  }
  ${SubscriptionFieldsFragmentDoc}
`;
export function useSubscriptionPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>
) {
  return Apollo.useQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(
    SubscriptionPlanDocument,
    baseOptions
  );
}
export function useSubscriptionPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>
) {
  return Apollo.useLazyQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(
    SubscriptionPlanDocument,
    baseOptions
  );
}
export type SubscriptionPlanQueryHookResult = ReturnType<typeof useSubscriptionPlanQuery>;
export type SubscriptionPlanLazyQueryHookResult = ReturnType<typeof useSubscriptionPlanLazyQuery>;
export type SubscriptionPlanQueryResult = Apollo.QueryResult<
  SubscriptionPlanQuery,
  SubscriptionPlanQueryVariables
>;
export const UpdateSheetDocument = gql`
  mutation updateSheet(
    $id: ID!
    $name: String
    $starred: Boolean!
    $apiKeyEnabled: Boolean!
    $apiKey: String
    $allowedMethods: [String!]
    $allowedOriginsEnabled: Boolean!
    $allowedOrigins: [String!]
    $allowedIpsEnabled: Boolean!
    $allowedIps: [String!]
    $ttl: Int
  ) {
    sheetUpdate(
      input: {
        id: $id
        name: $name
        starred: $starred
        apiKeyEnabled: $apiKeyEnabled
        apiKey: $apiKey
        allowedMethods: $allowedMethods
        allowedOriginsEnabled: $allowedOriginsEnabled
        allowedOrigins: $allowedOrigins
        allowedIpsEnabled: $allowedIpsEnabled
        allowedIps: $allowedIps
        ttl: $ttl
      }
    ) {
      errors {
        field
        message
      }
      sheet {
        id
        name
        starred
        apiKeyEnabled
        apiKey
        allowedMethods
        allowedOriginsEnabled
        allowedOrigins
        allowedIpsEnabled
        allowedIps
        ttl
      }
    }
  }
`;
export type UpdateSheetMutationFn = Apollo.MutationFunction<
  UpdateSheetMutation,
  UpdateSheetMutationVariables
>;
export function useUpdateSheetMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSheetMutation, UpdateSheetMutationVariables>
) {
  return Apollo.useMutation<UpdateSheetMutation, UpdateSheetMutationVariables>(
    UpdateSheetDocument,
    baseOptions
  );
}
export type UpdateSheetMutationHookResult = ReturnType<typeof useUpdateSheetMutation>;
export type UpdateSheetMutationResult = Apollo.MutationResult<UpdateSheetMutation>;
export type UpdateSheetMutationOptions = Apollo.BaseMutationOptions<
  UpdateSheetMutation,
  UpdateSheetMutationVariables
>;
export const ValidateUrlDocument = gql`
  mutation validateURL($url: String!) {
    sheetValidateUrl(input: { url: $url }) {
      isValid
      errors {
        message
      }
    }
  }
`;
export type ValidateUrlMutationFn = Apollo.MutationFunction<
  ValidateUrlMutation,
  ValidateUrlMutationVariables
>;
export function useValidateUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<ValidateUrlMutation, ValidateUrlMutationVariables>
) {
  return Apollo.useMutation<ValidateUrlMutation, ValidateUrlMutationVariables>(
    ValidateUrlDocument,
    baseOptions
  );
}
export type ValidateUrlMutationHookResult = ReturnType<typeof useValidateUrlMutation>;
export type ValidateUrlMutationResult = Apollo.MutationResult<ValidateUrlMutation>;
export type ValidateUrlMutationOptions = Apollo.BaseMutationOptions<
  ValidateUrlMutation,
  ValidateUrlMutationVariables
>;

export const UserStripeCheckoutSessionCreateDocument = gql`
  mutation UserStripeCheckoutSessionCreate($planId: String!, $userId: ID!) {
    userStripeCheckoutSessionCreate(input: { planId: $planId, id: $userId }) {
      errors {
        ...errorFields
      }
      sessionId
    }
  }
  ${ErrorFieldsFragmentDoc}
`;

//SubscriptionCheckout
export const UserStripeSubscriptionCheckoutSessionCreateDocument = gql`
  mutation UserStripeSubscriptionCheckoutSessionCreate($stripeProductId: String!, $userId: ID!) {
    userStripeSubscriptionCheckoutSessionCreate(
      input: { stripeProductId: $stripeProductId, id: $userId }
    ) {
      errors {
        ...errorFields
      }
      sessionId
    }
  }
  ${ErrorFieldsFragmentDoc}
`;

export type UserStripeCheckoutSessionCreateMutationFn = Apollo.MutationFunction<
  UserStripeCheckoutSessionCreateMutation,
  UserStripeCheckoutSessionCreateMutationVariables
>;
export function useUserStripeCheckoutSessionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserStripeCheckoutSessionCreateMutation,
    UserStripeCheckoutSessionCreateMutationVariables
  >
) {
  return Apollo.useMutation<
    UserStripeCheckoutSessionCreateMutation,
    UserStripeCheckoutSessionCreateMutationVariables
  >(UserStripeCheckoutSessionCreateDocument, baseOptions);
}

//SubscriptionCheckout
export function useUserStripeSubscriptionCheckoutSessionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserStripeSubscriptionCheckoutSessionCreateMutation,
    UserStripeSubscriptionCheckoutSessionCreateMutationVariables
  >
) {
  return Apollo.useMutation<
    UserStripeSubscriptionCheckoutSessionCreateMutation,
    UserStripeSubscriptionCheckoutSessionCreateMutationVariables
  >(UserStripeSubscriptionCheckoutSessionCreateDocument, baseOptions);
}

export type UserStripeCheckoutSessionCreateMutationHookResult = ReturnType<
  typeof useUserSubscriptionCreateMutation
>;
export type StripeCheckoutSessionCreateMutationResult = Apollo.MutationResult<
  UserStripeCheckoutSessionCreateMutation
>;
export type StripeCheckoutSessionCreateMutationOptions = Apollo.BaseMutationOptions<
  UserStripeCheckoutSessionCreateMutation,
  UserStripeCheckoutSessionCreateMutationVariables
>;

// Generated on 2020-11-09T15:42:05-03:00

export type MutationUserDeactivateArgs = {
  input: UserDeactivateMutationInput;
};
/** Deactivate a user. */
export type UserDeactivateMutationPayload = {
  __typename?: 'UserDeactivateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserDeactivateMutationInput = {
  uid: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserDeactivateMutationVariables = Exact<{
  uid: Scalars['String'];
}>;

export type UserDeactivateMutation = { __typename?: 'Mutation' } & {
  userDeactivate?: Maybe<
    { __typename?: 'UserDeactivateMutationPayload' } & Pick<
      UserDeactivateMutationPayload,
      'success'
    >
  >;
};
export const UserDeactivateDocument = gql`
  mutation userDeactivate($uid: String!) {
    userDeactivate(input: { uid: $uid }) {
      success
    }
  }
`;
export type UserDeactivateMutationFn = Apollo.MutationFunction<
  UserDeactivateMutation,
  UserDeactivateMutationVariables
>;
export function useUserDeactivateMutation(
  baseOptions?: Apollo.MutationHookOptions<UserDeactivateMutation, UserDeactivateMutationVariables>
) {
  return Apollo.useMutation<UserDeactivateMutation, UserDeactivateMutationVariables>(
    UserDeactivateDocument,
    baseOptions
  );
}
export type UserDeactivateMutationHookResult = ReturnType<typeof useUserDeactivateMutation>;
export type UserDeactivateMutationResult = Apollo.MutationResult<UserDeactivateMutation>;
export type UserDeactivateMutationOptions = Apollo.BaseMutationOptions<
  UserDeactivateMutation,
  UserDeactivateMutationVariables
>;
